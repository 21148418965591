import React from 'react';
import Layout from '../components/Layout';
import Icon from '../components/Icon';
import { Link } from 'gatsby';

export default () => (
  <Layout pageTitle="Page Not Found" pageURL="/" pageDescription="Page not found">
      <div className="px-2 pt-12 pb-2 text-center">
        <div className="text-teal-700 flex justify-center">
          <Icon name="frown" width={100} />
        </div>
        <h5 className="text-3xl font-bold mb-2 text-teal-700">Page not found</h5>
        <p className="mb-3 max-w-sm m-auto">
          We're sorry, but this page has been moved or no longer exists.
        </p>
        <div>
          <Link
            to="/"
            className="m-auto block w-full sm:w-64 text-center shadow bg-teal-600 hover:bg-teal-500 focus:shadow-outline focus:outline-none text-white py-2 px-8 rounded"
          >
            BACK TO HOME PAGE
          </Link>
        </div>
      </div>
  </Layout>
)
